import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/13_zwau.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door13 = () => {
  const ingredients = [
    "Paprika",
    "Zwiebel",
    "Meersalz",
    "Sellerie",
    "Pfeffer",
    "Chili",
  ];

  const doorNr = 13;

  const shortDecription =
    "Wusstest du, dass...?\nIn Ungarn heißt Gulasch immer Pörkölt oder Paprikás. Der Rest der Welt sagt Gulasch oder Gulaschsuppe";

  const herbName = "Gulasch";

  const recepieExample = (
    <div>
      <h4>Gulasch</h4>
      400g Rindergulasch in 2 EL Butterschmalz scharf anbraten.
      <br />2 geschälte, geviertelte Zwiebeln kurz mitbraten.
      <br />
      30g Tomatenmark mitrösten.
      <br />
      Mit 150ml Rotwein ablöschen und einreduzieren lassen.
      <br />
      Gesamtes Fläschchen (1 EL) des Gulasch gewürzes und 2 TL Rohrzucker
      unterrühren.
      <br />
      Mit 250 ml Braten- oder Rinderfond auffüllen und mit geschlossenem Deckel
      mindestens 1,5 Stunden köcheln lassen.
    </div>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
