import MerryChristmas from "../../../assets/MerryChristmas.jpg";
export const Door24Micans = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Simona und Cestmir!</h2>
      <div>
      Wie schnell die Adventszeit doch vergangen ist, oder? Wir hoffen, wir konnten euch mit dem Gewürz-Adventskalender eine kleine Freude bereiten.<br/>
      Wir wissen ja, dass ihr sehr gerne die verschiedensten Fleischgerichte zubereitet. Daher haben wir für euch hinter dem 24ten Türchen den <b>Meat-Allrounter</b> von Just Spices versteckt. Egal ob Braten, Hackgerichte oder einfache Steaks, mit diesem Gewürz könnt ihr alle Fleischgerichte verfeinern. Wir finden das Gewürz super lecker und hoffen, dass es auchseuch schmeckt 😋.
      </div>
      <div style={{ marginTop: 10 }}>
      Berichtet uns gerne von eurem Lieblingsgewürz! Bilder von möglichen Gerichten, die ihr damit gekocht habt sind natürlich auch sehr willkommen 😉.
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir dir Frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
