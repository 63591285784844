import MerryChristmas from "../../../assets/MerryChristmas.jpg";

export const Door24Lacigovy = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Bambi!</h2>
      <div>
        Ich weiß, dass du Weihnachten eigentlich hasst und am liebsten am 24ten deine 7 Sachen packen würdest und entfliehen würdest. Wir hoffen, wir haben dir dieses Jahr mit diesem Kalender die Zeit etwas versüßt, oder eher gewürzt 😉<br/>
        Du bist ja unser kleiner Allessser, also haben wir lange überlegt welchen Gewürz-Mix wir dir hinter dem 24te Türchen verstecken. Wir wissen nicht wieso, aber wenn wir unsere Augen verschließen und uns dich glücklich vorstellen, grinst du uns in unseren Vorstellungen mit einem großen KFC-Bucket in der Hand an. Daher haben wir uns letztendlich für den Hähnchen-Allrounder von Just Spices entschieden. Wir hoffen, das Gewürz findest du mindestens genau so geil wie wir. 😊
        Bleib Gesund und sei vorallem auf dem Motorrad besonders behutsam! Wir haben dich mega gern. ❤️
      </div>
      <div style={{ marginTop: 10 }}>
        Berichte uns gerne von deinem Lieblingsgewürz! Bilder von möglichen Gerichten, die du damit gekocht hast sind natürlich auch sehr willkommen 😉.
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir dir frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
