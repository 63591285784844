import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/22_wqyn.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door22 = () => {
  const ingredients = [
    "Schwarzer Tellicherry PFeffer",
    "Meersalz",
    "Zomate",
    "Paprika",
    "Zwiebelraspel",
    "Rosmarin",
    "Knoblauchraspel",
    "Chiliraspel",
    "Salbei",
    "Thymian",
    "Fenchel",
    "Basilikum",
    "Majoran",
    "Oregano",
  ];

  const doorNr = 22;

  const shortDecription = "In Italien gibt es die meisten Vulkane Europas, denn hier treffen die europäische und die afrikanische Erdplatte aufeinander. Drei der Vulkane sind noch heute aktiv: Der Vesuv, der Ätna und der Stromboli. Da muss man schon mal aufpassen, dass nichts verbrennt - ähnlich wie beim heutigen Rezept.";

  const herbName = "Italian allrounder";

  const recepieExample = (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.hellofresh.de/recipes/mediterrane-fiorelli-mit-krauter-kase-broseln-621f11f6ba62684c247f9674"
    >
      Mediterrane Fiorelli mit Kräuter-Käse-Bröseln
    </Link>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb"/>
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
