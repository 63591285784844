import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/18_puax.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door18 = () => {
  const ingredients = ["Chilliflocken"];

  const doorNr = 18;

  const shortDecription =
    "Wusstest du, dass...? Die höchste konzentration des Alkaloids Capsaicin - welches für die Schärfe verantwortlich ist - liegt in den Scheidewänden der Chilli. Folglich sind die Scheidewände auch das Schärfste an einer Chilli und nicht, wie oft angenommen, die Samen.";

  const herbName = "Chilliflocken";

  const recepieExample = (
    <div>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.chefkoch.de/rezepte/2520581395237137/Wurzelgemuese-Risotto-mit-Gremolata.html"
      >
        Wurzelgemüse-Risotto mit Gremolata
      </Link>
      <br />
      Optional kann dieses Rezept auch mit ein paar kleine Fleisch Medaillons
      (Rind oder Schwein) serviert werden
    </div>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
