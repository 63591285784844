import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/1_uzzp.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door1 = () => {
  const ingredients = [
    "Meersalz",
    "Geräuchertes Salz",
    "Rohrzucker",
    "Tomate",
    "Paprika",
    "Knoblauch",
    "Chili",
    "Rosmarin",
    "Thymian",
    "Salbei",
    "Koriander",
    "Kurkuma",
    "Bockshornkleesaat",
    "Fenchel",
    "Kreuzkümmel",
    "Schwarzer Pfeffer",
    "Nelke",
  ];

  const doorNr = 1;

  const breadcrumb = (
    <Breadcrumbs maxItems={3} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">Hähnchen Allrounder</h1>
      <i>Das perfekte Gewürz für alle Geflügelgerichte</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                style={{ flexGrow: 1 }}
                key={i}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.chefkoch.de/rezepte/2645711415716479/Fruchtiges-Ofenhaehnchen-mit-Kartoffeln.html"
      >
        Fruchtiges Ofenhähnchen mit Kartoffeln
      </Link>
    </>
  );
};
