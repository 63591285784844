import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Landing } from "./pages/Landing";
import { Door1 } from "./pages/doors/Door1";
import { Door2 } from "./pages/doors/Door2";
import { Door3 } from "./pages/doors/Door3";
import { Door4 } from "./pages/doors/Door4";
import { Door5 } from "./pages/doors/Door5";
import { Door6 } from "./pages/doors/Door6";
import { Door7 } from "./pages/doors/Door7";
import { Door8 } from "./pages/doors/Door8";
import { Door9 } from "./pages/doors/Door9";
import { Door10 } from "./pages/doors/Door10";
import { Door11 } from "./pages/doors/Door11";
import { Door12 } from "./pages/doors/Door12";
import { Door13 } from "./pages/doors/Door13";
import { Door14 } from "./pages/doors/Door14";
import { Door15 } from "./pages/doors/Door15";
import { Door16 } from "./pages/doors/Door16";
import { Door17 } from "./pages/doors/Door17";
import { Door18 } from "./pages/doors/Door18";
import { Door19 } from "./pages/doors/Door19";
import { Door20 } from "./pages/doors/Door20";
import { Door21 } from "./pages/doors/Door21";
import { Door22 } from "./pages/doors/Door22";
import { Door23 } from "./pages/doors/Door23";
import { Door24Klaus } from "./pages/doors/Door24/Door24Klaus";
import { Door24Nagls } from "./pages/doors/Door24/Door24Nagls";
import { Door24Micans } from "./pages/doors/Door24/Door24Micans";
import { Door24LisaOmaOpa } from "./pages/doors/Door24/Door24LisaOmaOpa";
import { Door24Margit } from "./pages/doors/Door24/Door24Margit";
import { Door24Voddi } from "./pages/doors/Door24/Door24Voddi";
import { Door24Lacigovy } from "./pages/doors/Door24/Door24Bambi";

export const SiteRoutes = [
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/uzzp",
    element: <Door1/>,
    doorNumber: 1
  },
  {
    path: "/kgnb",
    element: <Door2/>,
    doorNumber: 2
  },
  {
    path: "/ojcj",
    element: <Door3/>,
    doorNumber: 3
  },
  {
    path: "/tzgx",
    element: <Door4/>,
    doorNumber: 4
  },
  {
    path: "/lini",
    element: <Door5/>,
    doorNumber: 5
  },
  {
    path: "/cffx",
    element: <Door6/>,
    doorNumber: 6
  },
  {
    path: "/vcum",
    element: <Door7/>,
    doorNumber: 7
  },
  {
    path: "/ecbt",
    element: <Door8/>,
    doorNumber: 8
  },
  {
    path: "/vgea",
    element: <Door9/>,
    doorNumber: 9
  },
  {
    path: "/xpfp",
    element: <Door10/>,
    doorNumber: 10
  },
  {
    path: "/fgbq",
    element: <Door11/>,
    doorNumber: 11
  },
  {
    path: "/eulh",
    element: <Door12/>,
    doorNumber: 12
  },
  {
    path: "/zwau",
    element: <Door13/>,
    doorNumber: 13
  },
  {
    path: "/iaeg",
    element: <Door14/>,
    doorNumber: 14
  },
  {
    path: "/rdyu",
    element: <Door15/>,
    doorNumber: 15
  },
  {
    path: "/rtxh",
    element: <Door16/>,
    doorNumber: 16
  },
  {
    path: "/ppdy",
    element: <Door17/>,
    doorNumber: 17
  },
  {
    path: "/puax",
    element: <Door18/>,
    doorNumber: 18
  },
  {
    path: "/tfgi",
    element: <Door19/>,
    doorNumber: 19
  },
  {
    path: "/orlt",
    element: <Door20/>,
    doorNumber: 20
  },
  {
    path: "/lqnv",
    element: <Door21/>,
    doorNumber: 21
  },
  {
    path: "/wqyn",
    element: <Door22/>,
    doorNumber: 22
  },
  {
    path: "/uups",
    element: <Door23/>,
    doorNumber: 23
  },
  {
    path: "/vesb",
    element: <Door24Nagls/>,
    doorNumber: 24
  },
  {
    path: "/okqi",
    element: <Door24Klaus/>,
    doorNumber: 24
  },
  {
    path: "/brvi",
    element: <Door24Micans/>,
    doorNumber: 24
  },
  {
    path: "/myjy",
    element: <Door24LisaOmaOpa/>,
    doorNumber: 24
  },
  {
    path: "/cedq",
    element: <Door24Margit/>,
    doorNumber: 24
  },
  {
    path: "/smpc",
    element: <Door24Voddi/>,
    doorNumber: 24
  },
  {
    path: "/sygp",
    element: <Door24Lacigovy/>,
    doorNumber: 24
  },
];

export const PageRouter = () => {
  const router = createBrowserRouter(SiteRoutes);

  return <RouterProvider router={router} />;
};
