import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/21_lqnv.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door21 = () => {
  const ingredients = [
    "Paprika",
    "Chili",
    "Zwiebeln",
    "Pfeffer",
    "Knoblauch",
    "Koriander",
    "Kurkuma",
    "Kreuzkümmel",
    "Bockshornklee",
    "Ingwer",
    "Nelken",
    "Meersalz",
  ];

  const doorNr = 21;

  const shortDecription =
    "Da die Chinesen jahrhundertelang bitter unter Hungersnöten leiden mussten, ist ihr Einfallsreichtum in Bezug auf die Essbarkeit von Lebewesen unübertroffen. Spötter behaupten, Chinesen äßen alles, was vier Beine hat, außer es ist ein Tisch; alles was fliegt, außer Flugzeugen; und alles unter Wasser außer U-Booten.";

  const herbName = "China";

  const recepieExample = (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.chefkoch.de/rezepte/1890741307796634/Asiatische-Bratnudeln.html"
    >
      Asiatische Bratnudeln
    </Link>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
