import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/17_ppdy.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door17 = () => {
  const ingredients = [
    "Meersalz",
    "schwarzer Tellicherry Pfeffer",
    "Paprikaraspeln",
    "Petersilie",
    "Schnittlauch",
  ];

  const doorNr = 17;

  const shortDecription =
    "In Deutschland werden jährlich über 200 Eier pro Kopf verzehrt. Was sich auf den ersten Blick übermäßig viel anhört, lässt sich bei näherer Betrachtung leicht erklären. Eier finden sich in Gebäck, Nudeln und Co. wieder.";

  const herbName = "Rührei Gewürzmix";

  const recepieExample = (
    <div>
      3 Eier pro Portion aufschlagen und in ein hohes Rührgefäß geben.
      <br />
      Etwas sprudeliges Wasser mit Druck in das Gefäß spritzen bis eine kleine
      Schicht Schaum auf dem Rührei schwimmt. Tipp: Halte deinen Finger auf die
      Öfnung einer Mineralwasserflasche und kippe sie nach vorne. Lasse dabei
      etwas Wasser zwischen deinem Finger und der Flasche in das Gefäß spritzen.
      <br />
      Mit etwas (ca. 1 TL pro 3 Eier) Rührei Gewürzmix würzen und alles mit
      einer Gabel verquirlen bis das Rührei eine homogene Farbe hat.
      <br />
      Eine Pfanne mit etwas Öl oder Butter fetten und warm werden lassen.{" "}
      <i>
        Optional: In der Pfanne etwas gewürfelten Schinken oder Speck anbraten.
      </i>
      <br />
      Nachdem die Pfanne warm geworden ist, das Rührei in die Pfanne geben und
      sofort beginnen, mit einem Pfannenwender das anstockende Ei von der Seite
      zur Mitte hin zu scharben und das Ei "einsammeln".
      <br />
      Nach einiger Zeit sammelt sich so in der Mitte ein Ei-Berg. Etwas Käse
      darüber streuen und servieren. Wer es trockener mag kann den Rührei-Berg
      auch umdrehen und durchrühren und kurz weiter garen.
    </div>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
