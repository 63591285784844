import MerryChristmas from "../../../assets/MerryChristmas.jpg";

export const Door24Voddi = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Voddi!</h2>
      <div>
        Wie wir erfuhren, findest du Weihnachten eigentlich nicht so dufte! Wir hoffen, wir konnten durch diesen Kalender dieser Weihnachtszeit zumindest etwas Würze verpassen.<br/>
        Da du kürzlich ausgezogen bist, dachten wir uns, wir packen dir das <b>Burger Gewürz</b> von Just Spices hinter dein 24. Türchen. Burger sind die klassischen Allrounder in der Hausmannsküche. Schnell gemacht, sehr vielseitig und den eigenen Vorlieben entsprechend anpassbar. Mit diesem Gewürz kannst du deinem nächsten Burger noch das letzte etwas verpassen. Wir hoffen, es schmeckt.😋.
        Bleib die coole Socke die du bist! Wir haben dich mega gern. ❤️
      </div>
      <div style={{ marginTop: 10 }}>
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir dir Frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
