import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/12_eulh.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door12 = () => {
  const ingredients = [
    "Zwiebel",
    "Paprika",
    "Knoblauch",
    "Tomate",
    "Chili",
    "Kreuzkümmel",
    "Oregano",
    "Petersilie",
    "Pfeffer",
    "Speisesalz",
    "Zucker",
  ];

  const doorNr = 12;

  const shortDecription =
    "Everybody loves something, even if it's only tortillas\n- Pema Chodron";

  const herbName = "Tortillia Mix";

  const recepieExample = (
    <div>
      <h4>Tortilla con Pollo</h4>
      Salat, Gurke, Tomaten und Hähnchenbrust herschneiden.
      <br />
      <br />
      Hänchenbrust mit dem Tortilla Mix und etwas Olivenöl marinieren. Danach in
      einer Pfanne anbraten und beiseite stellen.
      <br />
      <br />
      Tortillas in einer Pfanne ohne Fett von jeder Seite kurz erwärmen und mit
      beliebiger Sauce (z.B. Salsa Sauce) bestreichen. Anschließend das
      vorbereitete Gemüse und die Hähnchenbrust in die Mitte der Tortillas geben
      und etwas Käse darüber sträuen.
      <br />
      <br />
      Die Seiten des Tortilla einklappen und aufrollen.
      <br />
      <br />
      Optional: Wär es etwas schärfer mag kann mit beliebiger Hot Sauce
      nachwürzen
    </div>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
