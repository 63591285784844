import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/14_iaeg.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door14 = () => {
  const ingredients = [
    "Paprika",
    "Koriander",
    "Zimt",
    "Fenchel",
    "Ingwer",
    "Chili",
    "Knoblauch",
    "Zitronengras",
    "Kurkuma",
    "Kardamom (in Schale vermahlen)",
    "Sternanis",
    "Nelken",
    "Petersilie",
    "Habanero Chili",
  ];

  const doorNr = 14;

  const shortDecription =
    "Scharf (gelbes Curry), schärfer (rotes Curry), am schärfesten (grünes Curry)";

  const herbName = "Rotes Thai Curry";

  const recepieExample = (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.azafran.de/blog/rotes-thai-curry-rezept.html"
    >
      Rotes Thai Curry
    </Link>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
