import MerryChristmas from "../../../assets/MerryChristmas.jpg";

export const Door24LisaOmaOpa = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Hermine und Karl!</h2>
      <div>
      Wie schnell die Adventszeit doch vergangen ist, oder?
      Wir wissen, dass bei euch gerne Fisch auf den Tisch kommt - am liebsten natürlich frisch und selbst gefangen. Doch auch, wenn mal kein Fisch anbeisst, könnt ihr mit dem <b>Fischgewürz</b> von Just Spices allen Arten von Fischgerichten eine besondere Note verleihen.
      Lasst es euch schmecken!
      </div>
      <div style={{ marginTop: 10 }}>
      Berichtet uns gerne von eurem Lieblingsgewürz! Bilder von möglichen Gerichten, die ihr damit gekocht habt sind natürlich auch sehr willkommen 😉.
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir euch Frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
