import TextField from "@mui/material/TextField";
import WelcomeImage from "../assets/Weihnachten.jpeg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiteRoutes } from "../PageRouter";

type DoorCodeState = {
  error: boolean;
  errorText?: string;
  value: string;
};

export const Landing = () => {
  const [doorCodeState, setDoorCodeState] = useState<DoorCodeState>({
    error: false,
    errorText: undefined,
    value: "",
  });
  const navigate = useNavigate();

  const handleInput = (input: string) => {
    const lowercaseInput = input.toLocaleLowerCase();
    if (input.length === 4) {
      if (
        SiteRoutes.filter(
          (element) => element.path.toLocaleLowerCase() === `/${lowercaseInput}`
        ).length <= 0
      ) {
        if (lowercaseInput === "axtf") {
          setDoorCodeState({
            error: true,
            errorText:
              "Das war nur ein Beispiel 😋 - Falls du ein Easteregg erwartet hast - Here you go: 🐣",
            value: input,
          });
        } else {
          setDoorCodeState({
            error: true,
            errorText: "Ungültiger Code",
            value: input,
          });
        }
      } else {
        navigate(`/${lowercaseInput}`);
      }
    } else if (input.length > 4) {
      setDoorCodeState({
        error: true,
        errorText: "Ein Türchen Code hat maximal 4 Stellen",
        value: input,
      });
    } else {
      setDoorCodeState({
        error: false,
        errorText: undefined,
        value: input,
      });
    }
  };

  return (
    <>
      <img
        src={WelcomeImage}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO</h2>
      <div>
        Es ist wieder diese Zeit des Jahres. Draußen wird es kalt, drinnen wird
        es kuschelig. Wir steuern auf Weihnachten zu. Um eure Wartezeit
        etwas Würze zu verleihen, haben wir euch für die nächsten 24 Tage eine
        kleine kulinarische Sammlung aus nah und fern zusammengestellt. Auf
        dieser Seite findet ihr ein paar kleine Hintergrundinfos und Rezeptideen
        zu den Gewürzen aus diesem Kalender. Scannt dafür einfach die QR-Codes
        unten an den Fläschchen oder gebt den Code manuell hier ein:
      </div>
      <div style={{ marginTop: 10 }}>
        <TextField
          sx={{ width: "100%" }}
          label="Türchencode (z.B. AXTF)"
          variant="filled"
          error={doorCodeState.error}
          helperText={doorCodeState.errorText}
          value={doorCodeState.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleInput(event.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir euch eine schöne und sinnliche Adventszeit.
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
