import { Breadcrumbs, Chip, Link, Stack } from "@mui/material";
import HerbImage from "../../assets/doors/5_lini.jpeg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SiteRoutes } from "../../PageRouter";

export const Door5 = () => {
  const ingredients = ["Estragon"];

  const doorNr = 5;

  const shortDecription =
    "Die Bitterstoffe des Estragon regen die Bildung von Magensäure an und fördern die Verdauung gerade bei fettreichen Speisen. Die ätherischen Inhaltsstoffe der Pflanze können Linderung bei Blähungen verschaffen. In der Pflanzenheilkunde wendet man Estragon Tee zur Anregung des Stoffwechsels und der Nierentätigkeit an.";

  const herbName = "Estragon";

  const recepieExample = (
    <div>
      Nach belieben Gemüse (z.B. Tomaten, Salat, Gurken, Paprika, Sojasprossen,
      etc.) herschneiden und zu einem Salat zusammenfügen. Für das Frenchdressing
      8 EL Olivenöl, 2 EL Essig, 2 EL scharfen Senf, 1 TL Tomatenmark, 1 TL
      Honig, 1 TL Estragon mit einander verrühren und mit Salz & Pfeffer
      abschmecken. Anschließend über den Salat verteilen und servieren.
    </div>
  );

  const breadcrumb = (
    <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        color="inherit"
        href="/"
      >
        Start
      </Link>
      {SiteRoutes.map((route) => {
        if (route.doorNumber && route.doorNumber <= doorNr) {
          return (
            <Link
              key={route.path}
              underline="hover"
              color="inherit"
              href={route.path}
            >
              Tag {route.doorNumber}
            </Link>
          );
        } else {
          return undefined;
        }
      })}
    </Breadcrumbs>
  );

  return (
    <>
      <img src={HerbImage} className="herb_img" alt="herb" />
      {breadcrumb}
      <h1 className="herb_name">{herbName}</h1>
      <i>{shortDecription}</i>
      <h3 className="content">Inhalt</h3>
      <div>
        <Stack
          spacing={1}
          direction="row"
          useFlexGap
          flexWrap="wrap"
          justifyContent="center"
        >
          {ingredients.map((ingredient, i) => {
            return (
              <Chip
                key={i}
                style={{ flexGrow: 1 }}
                label={ingredient}
                variant={i % 2 === 0 ? "filled" : "outlined"}
              />
            );
          })}
        </Stack>
      </div>
      <h3>Rezeptidee</h3>
      {recepieExample}
    </>
  );
};
