import MerryChristmas from "../../../assets/MerryChristmas.jpg";

export const Door24Klaus = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Klaus!</h2>
      <div>
        Wie wir erfuhren, findest du Weihnachten OK! Wir hoffen, wir konnten durch diesen Kalender das Rating dieses Jahr zumindest zu einem OK+ anheben.<br/>
        Da du unser kleiner Alman bist, haben wir für dich für das 24. Türchen den Kartoffel-Allrounder von Just Spices ausgewählt. Egal ob Kartoffelgratin, Bratkartoffeln oder einfache Salzkartoffeln, mit diesem Gewürz kannst du alle Erdapfel-Gerichte verfeinern. Wir finden das Gewürz super lecker und hoffen, dass es dir auch schmeckt. 😋
        Pass auf dich auf und bleib wie du bist! Wir haben dich mega gern. ❤️
      </div>
      <div style={{ marginTop: 10 }}>
       Berichte uns gerne von deinem Lieblingsgewürz! Bilder von möglichen Gerichten, die du damit gekocht hast sind natürlich auch sehr willkommen 😉.
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir dir Frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
