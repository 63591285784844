import MerryChristmas from "../../../assets/MerryChristmas.jpg";

export const Door24Margit = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Margit, Eugen & Mario!</h2>
      <div>
      Wie schnell die Adventszeit doch vergangen ist, oder?
      Wenn ihr zum Essen einladet, kommen immer viele leckere Salatkreationen auf den Tisch. Mit dem <b>Salat Topping</b> von Just Spices könnt ihr euren Salaten den letzten Schliff verleihen. 
      Lasst es euch schmecken! 
      </div>
      <div style={{ marginTop: 10 }}>
      Berichtet uns gerne von eurem Lieblingsgewürz! Bilder von möglichen Gerichten, die ihr damit gekocht habt sind natürlich auch sehr willkommen 😉.
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir euch Frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
