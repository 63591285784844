import MerryChristmas from "../../../assets/MerryChristmas.jpg";

export const Door24Nagls = () => {
  return (
    <>
      <img
        src={MerryChristmas}
        alt="Welcome"
        style={{ width: "100%", maxHeight: 500, objectFit: "cover" }}
      />
      <h2>HOHOHO Gabi, Peter und Elena!</h2>
      <div>
        Wie schnell die Adventszeit doch vergangen ist, oder? Wir hoffen wir konnten euch mit dem Gewürz Adventskalender eine kleine Freude bereiten.<br/>
        Wie wir ja wissen, wird in eurem Haushalt das ein oder andere mal Kartoffeln zubereitet. Man munkelt, der ein oder andere würde sie liebsten einfach jeden Tag essen. Daher haben wir für euch hinter das 24te Türchen den <b>Kartoffel-Allrounder</b> von Just Spices versteckt. Egal ob Kartoffelgratin, Bratkartoffeln oder einfache Salzkartoffeln, mit diesem Gewürz könnt ihr alle Erdapfel-Gerichte verfeinern. Wir finden das Gewürz super lecker und hoffen, dass es euch auch schmeckt 😋.
      </div>
      <div style={{ marginTop: 10 }}>
        Berichtet uns gerne von eurem lieblings Gewürz und Bilder von möglichen Gerichten die ihr damit gekocht habt sind natürlich auch sehr willkommen 😉.
      </div>
      <div style={{ marginTop: 10 }}>
        In diesem Sinne wünschen wir euch Frohe Weihnachten!
      </div>
      <div style={{ marginTop: 10 }}>Lisa 🦊 und Michael 🐰</div>
    </>
  );
};
