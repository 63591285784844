import { Container, CssBaseline } from "@mui/material";
import { HeaderBar } from "./HeaderBar";
import { PageRouter } from "./PageRouter";
import { ToggleColorModeProvider } from "./ToggleColorModeProvider";

export const App = () => {
  return (
    <>
      <ToggleColorModeProvider>
        <CssBaseline />
        <HeaderBar />
        <Container>
          <PageRouter />
        </Container>
      </ToggleColorModeProvider>
    </>
  );
};
